/*
 * Copyright (C) 2018-2024 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

import React from "react"
import { P, match } from "ts-pattern"
import { type EnvironmentResult } from "@garden-io/platform-api-types"
import { AccordionRow } from "../../components/accordion-row"
import { Button } from "../../components/button"
import { EmptyState } from "../../components/empty-state"
import { ErrorState } from "../../components/error-state"
import { Cleanup, Environment, Logs, More } from "../../components/icons"
import { LoadingIndicator } from "../../components/loading-indicator"
import { Page, type ProjectPageProps } from "../../components/page"
import { StatusIndicator } from "../../components/status-indicator"
import { Text } from "../../components/text"
import { TimeIndicator } from "../../components/time-indicator"
import { useEnv, useModals } from "../../contexts"
import { tokens } from "../../design-system"
import { type Client, type PaginatedApiResult, prefetchApiQuery, useInfiniteApiQuery } from "../../queries"
import { getNamespacesWithSeparators } from "../activity"
import { aecTabs } from "./shared"

export const AECActivity = ({ project }: ProjectPageProps) => {
  const cleanupRunsQuery = useInfiniteApiQuery((api) => api.cleanupRuns.listCleanupRuns(project.id))
  const environmentsById = useProjectEnvironments(project.environments ?? [])
  const env = useEnv()
  return (
    <Page
      scope="project"
      name="automatic-environment-cleanup"
      title="Cleanup runs"
      tabs={env.freeTierEnabled ? undefined : aecTabs(project.id)}
      preview={{
        title: "Automatic environment cleanup",
        docs: "https://cloud.docs.garden.io/features/automatic-environment-cleanup",
        description:
          "With Automatic environment cleanups, you can automatically delete or scale down any Kubernetes namespace resources deployed with Garden.",
        Icon: Cleanup,
        bookDemoLink: "https://garden.io/contact?utm_source=garden-app&utm_medium=cleanup&utm_id=app",
      }}
    >
      {match(cleanupRunsQuery)
        .with({ status: "loading" }, () => <LoadingIndicator />)
        .with({ isEmpty: true }, () => (
          <EmptyState
            title="There are no cleanup runs"
            Icon={Cleanup}
            description={<Text>No cleanups have run in this project.</Text>}
          />
        ))
        .with({ status: "error", error: P.select("error") }, ({ error }) => (
          <ErrorState
            title="Something went wrong"
            description={
              error?.apiErrorMessage ?? "Error loading cleanup runs. If the problem persists, please contact support."
            }
          />
        ))
        .with({ status: "success", flatData: P.select("flatData") }, ({ flatData }) => (
          <div css={{ display: "flex", flexDirection: "column", gap: tokens.spacing[4] }}>
            {flatData.map((cleanupRun) => (
              <CleanupRunRow
                key={cleanupRun.id}
                projectId={project.id}
                environment={environmentsById[cleanupRun.environmentId]}
                cleanupRun={cleanupRun}
              />
            ))}
          </div>
        ))
        .exhaustive()}
      <div css={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 8 * 3 }}>
        <Button
          onClick={() => cleanupRunsQuery.fetchNextPage()}
          state={cleanupRunsQuery.isFetching ? "loading" : undefined}
          disabled={!cleanupRunsQuery.hasNextPage}
        >
          {!cleanupRunsQuery.hasNextPage ? "All cleanup runs loaded" : "Load more"}
        </Button>
      </div>
    </Page>
  )
}

const useProjectEnvironments = (environments: EnvironmentResult[]) => {
  return React.useMemo(
    () =>
      environments.reduce<Record<string, EnvironmentResult>>((acc, item) => {
        acc[item.id] = item
        return acc
      }, {}),
    [environments]
  )
}

type CleanupRun = PaginatedApiResult<Client, "cleanupRuns", "listCleanupRuns">

const CleanupRunRow = ({
  projectId,
  cleanupRun,
  environment,
}: {
  projectId: string
  cleanupRun: CleanupRun
  environment?: EnvironmentResult
}) => {
  const modals = useModals()
  const [showNamespaces, setShowNamespaces] = React.useState(false)
  const deletedNamespaceNames = getNamespacesWithSeparators(
    cleanupRun.resourcesToDelete.map((namespace) => namespace.name)
  )
  const cleanupRunFailed = cleanupRun.status === "error" || cleanupRun.status === "timed-out"

  return (
    <AccordionRow
      styles={{ minHeight: "initial" }}
      main={
        <div css={{ display: "flex", flexDirection: "column", gap: tokens.spacing[8] }}>
          <div
            css={{
              display: "flex",
              alignItems: "center",
              gap: tokens.spacing[6],
            }}
          >
            <Text weight="bold" css={{ display: "inline-flex", alignItems: "center" }}>
              #{cleanupRun.runNumber}
            </Text>
          </div>
          <Text
            color="primary"
            css={{
              display: "inline-flex",
              lineHeight: tokens.spacing[16],
              alignItems: "center",
              gap: tokens.spacing[4],
              flexWrap: "wrap",
            }}
          >
            <TimeIndicator color="primary" date={cleanupRun.createdAt} action="Started" />
            <Text>in</Text>
            {environment ? (
              <>
                <Environment title="Environment" styles={{ marginLeft: tokens.spacing[4] }} />
                <Text>{environment.name}</Text>
              </>
            ) : null}
            {cleanupRun.resourcesToDelete.length > 0 ? (
              <>
                <Text>
                  and {cleanupRunFailed ? `attempted to ${cleanupRun.mode}` : `${cleanupRun.mode}d`}{" "}
                  {cleanupRun.resourcesToDelete.length} namespace
                  {cleanupRun.resourcesToDelete.length === 1 ? "" : "s"}
                </Text>
                <Button
                  size="small"
                  variant="ghost"
                  Icon={More}
                  title="Show deleted resources"
                  onClick={() => setShowNamespaces((current) => !current)}
                />
              </>
            ) : null}
          </Text>
          {showNamespaces ? (
            <div>
              {deletedNamespaceNames.map((namespaceOrSeparator, index) => (
                <Text
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={index}
                  color="primary"
                  styles={{
                    display: "inline-flex",
                    alignItems: "center",
                    margin:
                      index === deletedNamespaceNames.length - 3
                        ? `0 ${tokens.spacing[4]}`
                        : index % 2
                        ? `0 ${tokens.spacing[4]} 0 0`
                        : "0",
                  }}
                >
                  {namespaceOrSeparator}
                </Text>
              ))}
            </div>
          ) : null}
        </div>
      }
      metaInfo={<TimeIndicator date={cleanupRun.updatedAt} action="Updated" size="small" />}
      actions={
        <>
          <StatusIndicator status={cleanupRun.status} />
          <Button
            size="small"
            onMouseEnter={() =>
              prefetchApiQuery((api) =>
                api.projects.getCleanupRunByRunNumber(projectId, cleanupRun.environmentId, cleanupRun.runNumber)
              )
            }
            onClick={() =>
              modals.open({
                type: "aec-logs",
                projectId,
                environmentId: cleanupRun.environmentId,
                runNumber: cleanupRun.runNumber,
              })
            }
            Icon={Logs}
            title="Open cleanup run logs"
          />
        </>
      }
    />
  )
}
