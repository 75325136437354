/*
 * Copyright (C) 2018-2024 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

import { marked } from "marked"
import React, { useEffect, useState } from "react"
import { tokens } from "../design-system"
import { fontSizes, fontWeights, textLineHeights } from "./text"

export const Markdown = ({ children, url }: { children?: React.ReactNode; url?: string }) => {
  const [content, setContent] = useState("")

  useEffect(() => {
    if (url) {
      fetch(url)
        .then((res) => res.text())
        .then((res: string) => setContent(res))
    }
  }, [url])

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          .Markdown p {
            font-size: ${fontSizes.regular};
            line-height: ${textLineHeights.regular};
            line-height: 1.5;
          }
          .Markdown h1, .Markdown h2, .Markdown h3, .Markdown h4, .Markdown h5 {
            margin-top: ${tokens.spacing[24]};
            font-size: ${fontSizes.large};
          }
          .Markdown code {
            padding: 0 ${tokens.spacing[4]};
            font-family: ${tokens.typography["font-family-sans-serif"]};
            font-size: ${fontSizes.regular};
            font-weight: ${fontWeights.regular};
            background-color: ${tokens.colors["element-100"]};
            border-radius: ${tokens.borderRadii["radius-regular"]}px;
          }
          .Markdown pre {
            padding: ${tokens.spacing[12]} ${tokens.spacing[16]};
            margin: 0;
            background-color: ${tokens.colors["element-100"]};
            border-radius: ${tokens.borderRadii["radius-regular"]}px;
          }
          .Markdown pre > code {
            background-color: none;
            font-family: ${tokens.typography["font-family-monospace"]};
            font-size: ${fontSizes.regular};
            font-weight: ${fontWeights.regular};
            padding: 0;
          }
          .Markdown hr {
            background-color: ${tokens.colors["element-300"]};
            border: none;
            height: 1px;
          }
          .Markdown a {
            color: ${tokens.colors["anchor-primary"]};
            text-decoration: none;
          }
          `,
        }}
      />
      <div
        className="Markdown"
        dangerouslySetInnerHTML={{
          __html: marked((content || children || "") as string, { breaks: true }),
        }}
      />{" "}
    </>
  )
}
