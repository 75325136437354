/*
 * Copyright (C) 2018-2024 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

import { css } from "@emotion/css"
import React from "react"
import { LinkButton } from "../components/button"
import { Page } from "../components/page"
import { Text } from "../components/text"
import { tokens } from "../design-system"

export const NotFound = () => (
  <Page scope="global" name="not-found" title="Page not found">
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        gap: tokens.spacing[12],
        marginTop: tokens.spacing[8],
      }}
    >
      <Text paragraph>We couldn’t find what you were looking for.</Text>
      <LinkButton variant="primary" to="/" className={css({ alignSelf: "flex-start" })}>
        Take me home
      </LinkButton>
    </div>
  </Page>
)
