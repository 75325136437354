/*
 * Copyright (C) 2018-2024 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

import { fromPartial } from "@total-typescript/shoehorn"
import React from "react"
import { Navigate, useMatch } from "react-router-dom"
import { P, match } from "ts-pattern"
import { type UserResult } from "@garden-io/platform-api-types"
import { CurrentOrganizationContext } from "../contexts/current-organization"
import { projectsPageOptions } from "../pages/projects"
import { getLivePageUrl } from "../pages/shared"
import { useUpdateUserMutation } from "../queries/deprecated/user"

export const UserNavigationScope = ({ user }: { user: UserResult }) => {
  return <Navigate to={getRedirectUrl(user)} replace />
}

/**
 * Return a redirect URL based on the provided scope.
 */
function getRedirectUrl(user: UserResult) {
  return match({
    organizationId: user.meta.currentOrganization?.id,
    projectId: user.meta.currentProject?.id,
    userOrganizations: user.organizations,
  })
    .with({ projectId: P.select("projectId", P.string) }, ({ projectId }) => getLivePageUrl({ projectId }))
    .with({ projectId: P.nullish, organizationId: P.select("organizationId", P.string) }, ({ organizationId }) =>
      projectsPageOptions.getPath(organizationId)
    )
    .with(
      { projectId: P.nullish, organizationId: P.nullish, userOrganizations: [P.select("organization", P.any)] },
      ({ organization }) => projectsPageOptions.getPath(organization.id)
    )
    .otherwise(() => "/switch-organization")
}

/**
 * Update the user's navigation scope in an external storage.
 */
export const usePersistedScope = (user?: UserResult) => {
  const organizationId = useMatch("/organizations/:organizationId/*")?.params.organizationId
  const projectId = useMatch("/projects/:projectId/*")?.params.projectId
  const updateUserMutation = useUpdateUserMutation()

  const currentOrganization = React.useContext(CurrentOrganizationContext)

  React.useEffect(() => {
    if (organizationId && user) {
      currentOrganization.setCurrentOrganization?.(user.organizations.find((org) => org.id === organizationId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, user, currentOrganization.setCurrentOrganization])

  React.useEffect(() => {
    if (organizationId && user) {
      updateUserMutation.mutateAsync({
        id: user.id,
        name: user.name,
        vcsUsername: user.vcsUsername,
        email: user.email,
        // @ts-expect-error This fails because the frontend types for the mutation functions are currently generated incorrectly. When fixed, this should not fail.
        meta: { ...user.meta, currentOrganization: { id: organizationId, name: "" } },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId])

  React.useEffect(() => {
    if (projectId && user) {
      updateUserMutation.mutateAsync({
        id: user.id,
        name: user.name,
        vcsUsername: user.vcsUsername,
        email: user.email,
        // @ts-expect-error This fails because the frontend types for the mutation functions are currently generated incorrectly. When fixed, this should not fail.
        meta: { ...user.meta, currentProject: { id: projectId, name: "" } },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId])
}

if (import.meta.vitest) {
  const { describe, test, expect } = import.meta.vitest

  describe("getRedirectUrl", () => {
    test("redirects to a project when there is a `projectId`", () => {
      expect(
        getRedirectUrl(
          fromPartial({
            meta: {
              currentProject: { id: "proj-1", name: "Project 1" },
              currentOrganization: { id: "org-1", name: "Organization 1" },
            },
          })
        )
      ).toMatchInlineSnapshot('"/projects/proj-1/live?view=logs"')
    })

    test("redirects to an organization when there is no `projectId`", () => {
      expect(
        getRedirectUrl(
          fromPartial({
            meta: {
              currentOrganization: { id: "org-1", name: "Organization 1" },
            },
          })
        )
      ).toMatchInlineSnapshot('"/organizations/org-1/projects"')
    })
  })
}
