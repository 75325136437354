/*
 * Copyright (C) 2018-2024 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */
import React from "react"
import { useNavigate } from "react-router-dom"
import { type OrganizationResult } from "@garden-io/platform-api-types"
import { LinkButton } from "../../components/button"
import { ChevronRight, Icon } from "../../components/icons"
import { type GlobalPageProps, Page } from "../../components/page"
import { SectionCard } from "../../components/section-card"
import { Text } from "../../components/text"
import { tokens } from "../../design-system"

export const SwitchOrganization = ({ user }: GlobalPageProps) => {
  const navigate = useNavigate()

  const handleSwitchOrganization = (org: OrganizationResult) => {
    navigate(`/organizations/${org.id}/projects`)
  }

  return (
    <Page
      name="organization-switch"
      scope="global"
      styles={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      action={<LinkButton onClick={() => navigate(-1)}>Go back</LinkButton>}
    >
      <img alt="Pink flower" src="/images/flower-green.png" css={{ width: 48, height: 46 }} />

      <Text styles={{ margin: `${tokens.spacing[16]} 0`, textAlign: "center" }} paragraph size="large">
        Welcome to Garden!
        <br />
        Please, choose an organization to continue.
      </Text>

      <div css={{ display: "grid", gridTemplateColumns: "1fr", gap: tokens.spacing[16] }}>
        {user.organizations.map((org) => (
          <SectionCard
            key={org.id}
            onClick={() => handleSwitchOrganization(org)}
            size="small"
            styles={{
              "display": "flex",
              "justifyContent": "space-between",
              "alignItems": "center",

              "lineHeight": "1rem",
              "minWidth": "400px",

              "&:hover": {
                "borderColor": tokens.colors["element-700"],
                "> svg": { display: "block" },
              },
            }}
          >
            {org.name}
            <Icon title="chevron-right" Component={ChevronRight} styles={{ display: "none" }} />
          </SectionCard>
        ))}
      </div>
    </Page>
  )
}
