/*
 * Copyright (C) 2018-2024 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

import { QueryClientProvider as ReactQueryClientProvider } from "@tanstack/react-query"
import React from "react"
import { HeadProvider } from "react-head"
import { BrowserRouter as Router } from "react-router-dom"
import { type Env } from "@garden-io/platform-api-types"
import {
  AnalyticsProvider,
  CommandsProvider,
  CurrentOrganizationContextProvider,
  EnvContext,
  ErrorsContextProvider,
  FeatureFlagContextProvider,
  KeyboardControlProvider,
  LayoutGlobalProvider,
  ModalsProvider,
  PageContextProvider,
  ProjectContextProvider,
  UiProvider,
  UserContextProvider,
  WebsocketProvider,
} from "../contexts"
import { ReactQueryDevtools, queryClient } from "../queries"
import { Reporter } from "../reporter"
import { StackStreamsContextProvider } from "../stack-streams"
import { InternalErrorBoundary } from "./error-boundary"

// Wrapper component for global components and providers. Providers are either global, i.e. irrelevant to the rendering
// context, or wrapped around the provided children. Note that providers that require access to the route context must
// be provided elsewhere (rendered within the Router component).
export const Providers = ({ userId, env, children }: { userId: string; env?: Env; children: React.ReactNode }) => {
  return (
    <HeadProvider>
      <Router>
        <ReactQueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          <ErrorsContextProvider>
            <InternalErrorBoundary
              enableReporting={!!env?.enableReporting}
              errorContext="Rendering the application failed."
            >
              <FeatureFlagContextProvider enabled={!!userId}>
                <UserContextProvider userId={userId}>
                  <CurrentOrganizationContextProvider>
                    <ProjectContextProvider>
                      <EnvContext.Provider value={env}>
                        <AnalyticsProvider env={env}>
                          <UiProvider>
                            <WebsocketProvider>
                              <CommandsProvider>
                                <StackStreamsContextProvider isModal={false}>
                                  <ModalsProvider>
                                    <KeyboardControlProvider>
                                      <LayoutGlobalProvider>
                                        <PageContextProvider>
                                          {children}
                                          <Reporter env={env} />
                                        </PageContextProvider>
                                      </LayoutGlobalProvider>
                                    </KeyboardControlProvider>
                                  </ModalsProvider>
                                </StackStreamsContextProvider>
                              </CommandsProvider>
                            </WebsocketProvider>
                          </UiProvider>
                        </AnalyticsProvider>
                      </EnvContext.Provider>
                    </ProjectContextProvider>
                  </CurrentOrganizationContextProvider>
                </UserContextProvider>
              </FeatureFlagContextProvider>
            </InternalErrorBoundary>
          </ErrorsContextProvider>
        </ReactQueryClientProvider>
      </Router>
    </HeadProvider>
  )
}
