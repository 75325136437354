/*
 * Copyright (C) 2018-2024 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

import React from "react"
import { LoadingIndicator } from "../components/loading-indicator"
import { Page } from "../components/page"
import { Text } from "../components/text"
import { useEnv } from "../contexts"
import { tokens } from "../design-system"
import { totalContextFetchDelay, useMissingValueError } from "../env"
import { useGardenPlatformName } from "../hooks"
import { Note } from "../ui-kit"
import { AuthButtons } from "."

export const CliLogin = () => {
  const env = useEnv()
  const platformName = useGardenPlatformName()
  const missingConfigError = useMissingValueError(env?.vcsProvider, totalContextFetchDelay)
  return (
    <Page scope="global" name="cli-login" title={`Connect to ${platformName}`} styles={{ alignItems: "flex-start" }}>
      <Text paragraph size="large" styles={{ margin: `${tokens.spacing[16]} 0` }}>
        After logging in, the Garden CLI will be connected to {platformName}, and you can return to your terminal.
      </Text>
      {env?.vcsProvider ? (
        <AuthButtons />
      ) : missingConfigError ? (
        <Note variant="danger">Couldn't connect to the API, please try again later.</Note>
      ) : (
        <LoadingIndicator styles={{ justifyContent: "flex-start" }} />
      )}
    </Page>
  )
}

export const CliLoginSuccess = () => {
  return (
    <Page scope="global" name="cli-login-success" title={``} styles={{ width: "100%" }}>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: tokens.spacing[12],
          paddingTop: tokens.spacing[80],
          width: "100%",
        }}
      >
        <Text paragraph size="large" styles={{ margin: `${tokens.spacing[16]} 0`, textAlign: "center" }}>
          You successfully connected your Garden CLI, amazing!
          <br />
          You can now close this page.
        </Text>
        <img alt="Pink flower" src="/images/flower-pink.png" css={{ width: 48, height: 46 }} />
      </div>
    </Page>
  )
}
