/*
 * Copyright (C) 2018-2024 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

import {
  type Header,
  type TableMeta,
  createColumnHelper,
  flexRender,
  functionalUpdate,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import React from "react"
import { useNavigate } from "react-router-dom"
import { type EnvironmentResultPartial } from "@garden-io/platform-api-types"
import { Button } from "../../components/button"
import { ArrowUp, ArrowUpRight, Icon } from "../../components/icons"
import { Page, type ProjectPageProps } from "../../components/page"
import { Table } from "../../components/table"
import { Text } from "../../components/text"
import { useEnv, useModals } from "../../contexts"
import { tokens } from "../../design-system"
import { getUrlWithParams } from "../../queries"
import { humanReadableSchedule, humanReadableTtl, prefetchAecSettingsModal } from "./aec-settings-modal"
import { aecTabs } from "./shared"

export const AECSettings = ({ project }: ProjectPageProps) => {
  const modals = useModals()
  const env = useEnv()
  const meta: AecTableMeta = { modals }
  const [sortState, setSortState] = useAecSortState()
  const table = useReactTable({
    data: project.environments ?? [],
    columns: getColumns(),
    meta,
    state: {
      sorting: [sortState],
    },
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: (updater) => {
      const newValue = functionalUpdate(updater, [sortState])
      setSortState(newValue[0])
    },
  })
  return (
    <Page
      scope="project"
      name="automatic-environment-cleanup"
      title="Cleanup runs"
      tabs={env.freeTierEnabled ? undefined : aecTabs(project.id)}
    >
      <Table>
        <thead css={{ backgroundColor: tokens.colors["element-100"] }}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeadCell key={header.id} header={header} />
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  css={{
                    height: 40,
                    borderBottom: `1px solid ${tokens.colors["border-secondary"]}`,
                    width: cell.column.getSize(),
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </Page>
  )
}

const TableHeadCell = ({ header }: { header: Header<EnvironmentResultPartial, unknown> }) => {
  const sorted = header.column.getIsSorted()
  return (
    <th
      key={header.id}
      colSpan={header.colSpan}
      onClick={header.column.getToggleSortingHandler()}
      css={{
        ...(header.column.getCanSort() ? { cursor: "pointer", userSelect: "none" } : {}),
        width: header.getSize(),
      }}
    >
      <div
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: tokens.spacing[4],
          maxHeight: tokens.spacing[24],
        }}
      >
        <Text color={sorted ? "primary" : "secondary"}>
          {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
        </Text>
        <Icon
          Component={ArrowUp}
          color="text-secondary"
          title={`Sort ${sorted === "asc" ? "ascending" : "descending"}`}
          css={{
            visibility: sorted ? "visible" : "hidden",
            transform: sorted === "desc" ? "none" : "rotate(180deg)",
          }}
        />
      </div>
    </th>
  )
}

const useAecSortState = () => {
  const navigate = useNavigate()

  const setSortState = React.useCallback(
    ({ desc, id }: { desc: boolean; id: string }) => {
      const url = getUrlWithParams("", {
        sort: id,
        desc,
      })
      navigate(url)
    },
    [navigate]
  )

  const searchParams = new URLSearchParams(window.location.search)
  const sortDesc = searchParams.get("desc")
  const sortId = searchParams.get("sort")

  return [
    {
      desc: sortDesc === "true",
      id: sortId || "environment",
    },
    setSortState,
  ] as const
}

const columnHelper = createColumnHelper<EnvironmentResultPartial>()

type AecTableMeta = TableMeta<EnvironmentResultPartial> & {
  modals: ReturnType<typeof useModals>
}

// @tanstack/react-table does not provide a way to extend the meta type cleanly.
const isAecMeta = (arg: unknown): arg is AecTableMeta => {
  return !!arg && typeof arg === "object" && "modals" in arg
}

const getColumns = () => [
  columnHelper.accessor((row) => row.name, {
    id: "environment",
    cell: (info) => <Text>{info.getValue()}</Text>,
    header: () => <span>Environment</span>,
  }),
  columnHelper.accessor((row) => row.aecSettings?.ttl, {
    id: "inactivity-schedue",
    header: () => "Inactivity schedule",
    enableSorting: false,
    cell: (info) => {
      const ttl = info.getValue()
      if (ttl) {
        return <Text color="primary">{humanReadableTtl(ttl)}</Text>
      } else {
        return <Text color="secondary">Doesn't expire</Text>
      }
    },
  }),
  columnHelper.accessor((row) => row.aecSettings?.schedules, {
    id: "repeat-schedue",
    header: () => "Repeat schedule",
    enableSorting: false,
    cell: (info) => {
      const schedules = info.getValue()
      const hasSchedules = schedules && schedules.length > 0
      if (hasSchedules) {
        return <Text color="primary">{humanReadableSchedule(schedules[0])}</Text>
      } else {
        return <Text color="secondary">Not scheduled</Text>
      }
    },
  }),
  columnHelper.display({
    id: "actions",
    enableSorting: false,
    maxSize: 40,
    cell: ({ row, table }) => {
      if (!isAecMeta(table.options.meta)) {
        throw new Error("Invalid table metadata.")
      }
      const { modals } = table.options.meta
      return (
        <div css={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            Icon={ArrowUpRight}
            title="Configure cleanups"
            size="small"
            variant="ghost"
            onMouseEnter={() =>
              prefetchAecSettingsModal({
                projectId: row.original.projectId,
                environmentId: row.original.id,
              })
            }
            onClick={() =>
              modals.open({
                type: "aec-settings",
                environmentId: row.original.id,
                projectId: row.original.projectId,
              })
            }
          />
        </div>
      )
    },
  }),
]
