/*
 * Copyright (C) 2018-2024 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

import React from "react"
import { type ConfigResponse, type Env } from "@garden-io/platform-api-types"
import { configureErrorHandling } from "./error-handling"

const wait = async (delayMs: number) => new Promise((resolve) => setTimeout(resolve, delayMs))

const delays: number[] = [50, 500, 1500]
export const totalContextFetchDelay = 2500 // Slightly above the actual sum.

const fetchRetry = <T>(url: string, retries?: number): Promise<T> => {
  return fetch(url).then((res) => {
    if (res.ok) {
      return res.json()
    }
    if (retries && retries > 0) {
      return wait(delays[retries]).then(() => fetchRetry(url, retries - 1))
    }
    throw new Error(res.statusText)
  })
}

export const useEnv = () => {
  const [env, setEnv] = React.useState<Env | undefined>(undefined)
  React.useEffect(() => {
    fetchRetry<ConfigResponse>("/api/config/", 3)
      .then((response) => {
        setEnv(response.data.env)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])
  React.useEffect(() => {
    if (env) {
      configureErrorHandling(env)
    }
  }, [env])
  return env
}

// Return true if the provided value is falsey after the provided timeout, otherwise return false.
export const useMissingValueError = (value: unknown, delayMs: number) => {
  const [missingValueError, setMissingValueError] = React.useState(false)
  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!value) {
        setMissingValueError(true)
      }
    }, delayMs)
    return () => void clearTimeout(timeoutId)
  }, [setMissingValueError, value, delayMs])
  return missingValueError
}
