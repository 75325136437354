/*
 * Copyright (C) 2018-2024 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

import React from "react"
import ReactDOM from "react-dom"
import { App } from "./app"

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
)

if (process.env.NODE_ENV === "development") {
  new EventSource("/esbuild").addEventListener("change", () => window.location.reload())
}
