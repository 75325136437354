/*
 * Copyright (C) 2018-2024 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

import React from "react"
import { Button } from "../components/button"
import { Settings } from "../components/icons"
import { type OrganizationPageProps, Page, type PageOptions } from "../components/page"
import { SectionCard } from "../components/section-card"
import { MonospacedText } from "../components/text"
import { tokens } from "../design-system"

export const themePageOptions = {
  icon: Settings,
  text: "Theme",
  getPath: () => "/theme",
  prefetch: () => {},
} satisfies PageOptions

export const Theme = (_pageProps: OrganizationPageProps) => {
  const [resets, setResets] = React.useState<Record<string, string>>({})
  return (
    <Page scope="global" name="theme" title="Theme">
      <SectionCard>
        {Object.entries(tokens.colors).map(([key, value]) => {
          const initialColor = document.documentElement.style.getPropertyValue(`--${key}`)
          return (
            <div key={key} css={{ display: "flex", alignItems: "center", gap: tokens.spacing[12] }}>
              <div css={{ height: 48, width: 48, backgroundColor: value }}>
                <input
                  type="color"
                  value={document.documentElement.style.getPropertyValue(`--${key}`)}
                  onChange={(event) => {
                    if (!resets[key] && event.target.value !== initialColor) {
                      setResets((current) => ({ ...current, [key]: initialColor }))
                    }
                    document.documentElement.style.setProperty(`--${key}`, event.target.value)
                  }}
                  css={{ appearance: "none", width: "100%", height: "100%", opacity: 0 }}
                />
              </div>
              <MonospacedText>{key}</MonospacedText>
              {resets[key] ? (
                <Button
                  onClick={() => {
                    document.documentElement.style.setProperty(`--${key}`, resets[key])
                    setResets((current) =>
                      Object.fromEntries(Object.entries(current).filter(([currentKey]) => currentKey !== key))
                    )
                  }}
                >
                  Reset
                </Button>
              ) : null}
            </div>
          )
        })}
      </SectionCard>
    </Page>
  )
}
