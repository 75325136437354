/*
 * Copyright (C) 2018-2024 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

import { type CSSObject } from "@emotion/css"
import {
  type ColumnDef,
  flexRender,
  functionalUpdate,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import React, { useCallback } from "react"
import { useNavigate, useParams } from "react-router-dom"
import {
  type Action,
  type ActionState,
  type InsightsSummaryResult,
  type ListTaskResultsResult,
  type OrganizationResult,
  type UserResult,
} from "@garden-io/platform-api-types"
import { type ActionLogsModalPayload, prefetchActionLogs } from "../../components/action-logs-modal"
import { actionStateToVariantProps, normalizeActionState } from "../../components/actions-list"
import { Button, ButtonLink } from "../../components/button"
import { EmptyState } from "../../components/empty-state"
import { Filter, type FilterConfig } from "../../components/filter"
import { Heading } from "../../components/heading"
import {
  ActionIcon,
  ArrowUp,
  Environment,
  Icon,
  Insights as InsightsIcon,
  Logs,
  Typography,
} from "../../components/icons"
import {
  InsightsBarChart,
  timeseriesOutcomesToChartData,
  timeseriesTimingsToChartData,
} from "../../components/insights-chart"
import { Link } from "../../components/link"
import { LoadingIndicator } from "../../components/loading-indicator"
import { Page, type ProjectPageProps } from "../../components/page"
import { SectionCard } from "../../components/section-card"
import { Table } from "../../components/table"
import { TagWithText } from "../../components/tag"
import { IconText, MonospacedText, Text, type TextProps } from "../../components/text"
import { useModals } from "../../contexts"
import { tokens } from "../../design-system"
import { getUrlWithParams, useApiQuery, useInfiniteApiQuery } from "../../queries"
import { Breadcrumbs } from "../../ui-kit"
import { useUsersOptions } from "../../utils/hooks/use-users-options"
import { parseQueryParamArray } from "../../utils/util"
import { getCommandDetailUrl, getCommandString } from "../commands/shared"
import { defaultNA, formatCell, formatDurationMs, getInsightsDetailPath } from "./insights-shared"

// Selection x1,x2 is in milliseconds, but might be a time in the middle of the day.
// We reformat the date to take into consideration from midnight until the end of the day.
const getIntervalFromSelection = (x1: number, x2: number) => {
  return {
    start: x1 < x2 ? new Date(x1).setHours(0, 0, 0, 0) : new Date(x2).setHours(0, 0, 0, 0),
    end: x2 > x1 ? new Date(x2).setHours(23, 59, 59, 999) : new Date(x1).setHours(23, 59, 59, 999),
  }
}

type ListTaskResultWithActionNames = ListTaskResultsResult & { name: string }

const columns: ColumnDef<ListTaskResultWithActionNames>[] = [
  {
    id: "timestamp",
    header: "Timestamp",
    accessorFn: (row) => row.startedAt,
    cell: (info) => <Text>{new Date(info.getValue<string>()).toLocaleString()}</Text>,
  },
  {
    id: "action-duration",
    header: "Duration",
    accessorFn: (row) => row.durationMsec,
    cell: (info) => {
      const duration = info.getValue<number>()

      if (!duration) {
        return <Text title={`Could not calculate duration`}>N/A</Text>
      }

      return <Text title={`${duration / 1000}s`}>{formatDurationMs(duration)}</Text>
    },
  },
  {
    id: "action-state",
    header: "Result",
    accessorFn: (row) => row.actionState,
    cell: (info) => {
      const actionState = info.getValue() as ActionState | undefined
      const normalizedActionState = normalizeActionState(actionState)
      const variantProps = actionStateToVariantProps(normalizedActionState)
      return (
        <TagWithText tagProps={{ variant: variantProps.variant, title: variantProps.label }}>
          {variantProps.label}
        </TagWithText>
      )
    },
  },
  {
    id: "command",
    header: "Command",
    accessorFn: (row) => row,
    cell: (info) => {
      const actionResult = info.getValue<ListTaskResultsResult>()
      const action = info.row.original
      const command = getCommandString(actionResult.coreSession.command, actionResult.coreSession.cliArgs)
      return (
        <Link
          to={getCommandDetailUrl({
            projectId: action.projectId,
            sessionId: action.coreSession.id,
            userId: action.user.id?.toString(),
          })}
          decorate={false}
          noWrapper
        >
          <MonospacedText color="primary" styles={{ ":hover": { textDecorationLine: "underline" } }} title={command}>
            {command}
          </MonospacedText>
        </Link>
      )
    },
  },
  {
    id: "user",
    header: "User",
    accessorFn: (row) => row.user.name,
    cell: (info) => {
      return <Text>{info.getValue<string>() || defaultNA}</Text>
    },
  },
  {
    id: "environment",
    header: "Environment",
    accessorFn: (row) => row.environment.name,
    cell: (info) => {
      return <Text>{info.getValue<string>()}</Text>
    },
  },
  {
    id: "logs",
    header: "",
    cell: (info) => {
      const action = info.row.original
      const disabled = false
      const title = disabled ? "There are no logs" : "Open logs"
      // Couldn't find another way to pass this callback to the cell.
      // Unfortunately need to cast the meta type.
      const meta = info.table.options.meta as any
      const openLogs = meta.openLogs as (payload: ActionLogsModalPayload) => void
      return (
        <Button
          size="small"
          Icon={Logs}
          title={title}
          disabled={disabled}
          onClick={() =>
            openLogs({
              type: "action-logs",
              coreSessionId: action.coreSession.id,
              actionId: action.actionId,
            })
          }
          onMouseEnter={() =>
            prefetchActionLogs({
              coreSessionId: action.coreSession.id,
              actionId: action.actionId,
            })
          }
          css={{
            ":disabled": {
              color: tokens.colors["text-tertiary"],
              cursor: "default",
            },
          }}
        />
      )
    },
  },
]

export const InsightsDetail = ({ user, project }: ProjectPageProps) => {
  const params = useParams<{ environmentId: string; actionKind: Action["kind"]; actionName: Action["name"] }>()
  const { environmentId, actionKind, actionName } = params
  const [selection, setSelection] = React.useState({ x1: -1, x2: -1, selecting: false })
  const modals = useModals()

  if (!environmentId || !actionKind || !actionName) {
    throw new Error("Page not found; expected an environment, action kind and action name")
  }

  const insightsQuery = useApiQuery((api) =>
    api.insights.actionsAggregate(project.organization.id, {
      projectId: project.id,
      environmentIds: [environmentId],
      actionKinds: [actionKind],
      actionName,
      timeseries: true,
    })
  )

  const actionsQuery = useInfiniteApiQuery((api) =>
    // FIXME @eysi: Use sessions.listActions
    api.projects.listActions(project.id, {
      environmentId,
      actionKind,
      actionName,
      userIds: parseQueryParamArray("userId"),
      success: parseStatusValueParam(),
      startDate:
        selection.x1 > -1 && selection.x2 > -1
          ? new Date(getIntervalFromSelection(selection.x1, selection.x2).start).toISOString()
          : undefined,
      endDate:
        selection.x1 > -1 && selection.x2 > -1
          ? new Date(getIntervalFromSelection(selection.x1, selection.x2).end).toISOString()
          : undefined,
    })
  )

  const navigate = useNavigate()
  const searchParams = new URLSearchParams(window.location.search)

  const sortDesc = searchParams.get("desc")
  const sortId = searchParams.get("sort")

  const sortState = {
    desc: sortDesc === "true",
    id: sortId || "timestamp",
  }
  const setSortState = ({ desc, id }: { desc: boolean; id: string }) => {
    const url = getUrlWithParams(
      getInsightsDetailPath({ projectId: project.id, environmentId, actionKind, actionName }),
      {
        sort: id,
        desc,
      }
    )
    navigate(url)
  }

  const openLogs = useCallback(
    (params: Omit<ActionLogsModalPayload, "type">) => {
      modals.open({ type: "action-logs", ...params })
    },
    [modals]
  )

  const table = useReactTable({
    // We augment the response with the action's name, which is not returned by the API but required on this page.
    data: (actionsQuery.flatData ?? []).map(
      (result): ListTaskResultWithActionNames => ({ ...result, name: actionName })
    ),
    columns,
    meta: {
      openLogs,
    },
    state: {
      sorting: [sortState],
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableSortingRemoval: false,
    onSortingChange: (updater) => {
      const newValue = functionalUpdate(updater, [sortState])
      setSortState(newValue[0])
    },
    autoResetPageIndex: true,
  })

  const queryFilters = useQueryFilters({ user, organization: project.organization })

  return (
    <Page scope="project" name="insights-detail" styles={{ gap: tokens.spacing[40] }}>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          gap: tokens.spacing[8],
          marginBottom: tokens.spacing[32],
        }}
      >
        <Breadcrumbs
          breadcrumbs={[
            {
              to: `/projects/${project.id}/insights`,
              label: "Insights",
            },
          ]}
        />
        <Heading level={3}>{`${actionKind}.${actionName}`}</Heading>

        <InsightsContextCard
          kind={actionKind}
          name={actionName}
          // If the environment has been deleted we cannot show its name.
          environmentName={project.environments.find((env) => env.id === params.environmentId)?.name}
          styles={{ alignSelf: "flex-start" }}
        />
      </div>

      {insightsQuery.isFetched && insightsQuery.data?.results?.[0] && (
        <>
          <InsightsTable insightsSummary={insightsQuery.data} />

          <div css={{ display: "flex", gap: tokens.spacing[16], justifyContent: "space-between" }}>
            <SectionCard css={{ flex: 1, padding: tokens.spacing[16] }}>
              <InsightsBarChart
                title="Mean execution time (last 30 days)"
                data={timeseriesTimingsToChartData({
                  points: insightsQuery.data?.results?.[0].timeseriesTimings || [],
                  startsAt: new Date(insightsQuery.data?.startDate),
                })}
                yAxisUnit="s"
                barProperties={[{ dataKey: "mean", fill: tokens.colors["element-success"], unit: "s" }]}
                setSelection={setSelection}
                selection={selection}
                yTickFormatter={(value) => parseFloat(value).toFixed(2)}
              />
            </SectionCard>
            <SectionCard css={{ flex: 1, padding: tokens.spacing[16] }}>
              <InsightsBarChart
                title="Number of successful or failed executions (last 30 days)"
                data={timeseriesOutcomesToChartData({
                  startsAt: new Date(insightsQuery.data?.startDate),
                  points: insightsQuery.data?.results?.[0].timeseriesOutcomes || [],
                })}
                barProperties={[
                  { dataKey: "success", fill: tokens.colors["element-success"], stackId: 1 },
                  { dataKey: "failure", fill: tokens.colors["element-error"], stackId: 1 },
                ]}
                setSelection={setSelection}
                selection={selection}
              />
            </SectionCard>
          </div>
          <div>
            <div css={{ marginBottom: tokens.spacing[12] }}>{queryFilters.filterNode}</div>
            {actionsQuery.isFetched && !selection.selecting && !actionsQuery.isEmpty ? (
              <>
                <div css={{ overflowX: "auto" }}>
                  <Table
                    css={{
                      "tr:hover .link-indicator": {
                        display: "inherit",
                      },
                    }}
                  >
                    <thead css={{ backgroundColor: tokens.colors["element-100"] }}>
                      {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                          {headerGroup.headers.map((header) => {
                            const sorted = header.column.getIsSorted()
                            return (
                              <th
                                key={header.id}
                                colSpan={header.colSpan}
                                onClick={header.column.getToggleSortingHandler()}
                                css={{
                                  ...(header.column.getCanSort() ? { cursor: "pointer", userSelect: "none" } : {}),
                                }}
                              >
                                <div css={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                  <Text color={sorted ? "primary" : "secondary"}>
                                    {header.isPlaceholder
                                      ? null
                                      : flexRender(header.column.columnDef.header, header.getContext())}
                                  </Text>
                                  <Icon
                                    color="text-secondary"
                                    Component={ArrowUp}
                                    title={`Sort ${sorted === "asc" ? "ascending" : "descending"}`}
                                    css={{
                                      visibility: sorted ? "visible" : "hidden",
                                      transform: sorted === "desc" ? "rotate(180deg)" : "none",
                                    }}
                                  />
                                </div>
                              </th>
                            )
                          })}
                        </tr>
                      ))}
                    </thead>
                    <tbody>
                      {table.getRowModel().rows.map((row) => (
                        <tr key={row.id}>
                          {row.getVisibleCells().map((cell) => (
                            <td
                              key={cell.id}
                              css={{
                                maxWidth: "20rem",
                              }}
                            >
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <div
                  css={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 12,
                    height: 64,
                  }}
                >
                  <Button
                    onClick={() => actionsQuery.fetchNextPage()}
                    state={actionsQuery.isLoading ? "loading" : undefined}
                    disabled={!actionsQuery.hasNextPage || actionsQuery.isFetching}
                  >
                    {actionsQuery.hasNextPage ? "Load more" : "All actions loaded"}
                  </Button>
                </div>
              </>
            ) : selection.selecting || actionsQuery.isLoading ? (
              <LoadingIndicator styles={{ margin: tokens.spacing[16], animation: "none" }} />
            ) : (
              <EmptyState
                Icon={InsightsIcon}
                title="No matching actions have run."
                description={
                  queryFilters.filtersApplied ? (
                    <ButtonLink onClick={queryFilters.resetFilters}>Reset all filters</ButtonLink>
                  ) : (
                    <Text styles={{ textAlign: "center" }}>
                      Insights are collected and aggregated on a daily basis. Try running a few Garden commands and come
                      back later.
                    </Text>
                  )
                }
                css={{ padding: tokens.spacing[32] }}
              />
            )}
          </div>
        </>
      )}
    </Page>
  )
}

const useQueryFilters = ({ user, organization }: { user: UserResult; organization: OrganizationResult }) => {
  const navigate = useNavigate()
  const params = new URLSearchParams(window.location.search)
  const filtersApplied = params.get("userId") || params.get("success")
  const resetFilters = () => {
    navigate(window.location.pathname)
  }

  const usersOptions = useUsersOptions({ user, organization })

  const filterConfig: FilterConfig[] = React.useMemo(() => {
    return [
      {
        label: "User",
        queryParamName: "userId",
        options: usersOptions.options,
      },
      {
        label: "Status",
        queryParamName: "success",
        options: [
          { key: "true", label: "Succeeded" },
          { key: "false", label: "Failed" },
        ],
      },
    ]
  }, [usersOptions.options])

  return {
    filterNode: <Filter config={filterConfig} isLoading={usersOptions.isLoading} />,
    filtersApplied,
    resetFilters,
  }
}

/**
 *
 * ATTENTION: this function is an edge case, we should get rid of it
 *
 * Parse query param from URL and converts to bool.
 *
 * Returns undefined if array of values is of length 2, because that means both true and false are selected
 */
const parseStatusValueParam = () => {
  const queryParams = new URLSearchParams(window.location.search)

  const values = queryParams.getAll("success")

  // We need to add this hack here to support the new multiselect filter
  // User can select success = true AND success = false, in that case we should just show all
  if (values.length === 2) {
    return undefined
  }

  return values[0] === "true" ? true : values[0] === "false" ? false : undefined
}

const InsightsContextCard = ({
  kind,
  name,
  environmentName,
  styles = {},
}: {
  kind: Action["kind"]
  name: Action["name"]
  environmentName?: string
  styles: CSSObject
}) => (
  <div
    css={{
      display: "flex",
      gap: tokens.spacing[40],
      alignItems: "center",
      padding: `${tokens.spacing[12]} ${tokens.spacing[16]}`,
      borderRadius: tokens.borderRadii["radius-regular"],
      backgroundColor: tokens.colors["element-100"],
      ...styles,
    }}
  >
    <div css={{ display: "flex", flexDirection: "column", gap: tokens.spacing[8] }}>
      <Text color="secondary">Kind</Text>
      <IconText weight="semi-bold" icon={<ActionIcon action={kind} title={null} />}>
        {kind}
      </IconText>
    </div>

    <div css={{ display: "flex", flexDirection: "column", gap: tokens.spacing[8] }}>
      <Text color="secondary">Name</Text>
      <IconText weight="semi-bold" icon={<Typography title={null} />}>
        {name}
      </IconText>
    </div>

    {environmentName ? (
      <div css={{ display: "flex", flexDirection: "column", gap: tokens.spacing[8] }}>
        <Text color="secondary">Environment</Text>
        <IconText weight="semi-bold" icon={<Environment title={null} />}>
          {environmentName}
        </IconText>
      </div>
    ) : null}
  </div>
)

const InsightsTable = ({ insightsSummary }: { insightsSummary?: InsightsSummaryResult }) => {
  const insights = insightsSummary?.results?.[0]
  if (!insights) {
    return null
  }

  const durationTrend = formatCell({
    value: insights.summaryTimings.mean.changePercent ?? null,
    unit: "%",
    isTrend: true,
    multiplier: 100,
  })
  const failureRate = formatCell({
    value: insights.summaryOutcomes.failureRate.value,
    unit: "%",
    multiplier: 100,
  })
  const failureTrend = formatCell({
    value: insights.summaryOutcomes.failureRate.changePercent ?? null,
    unit: "%",
    multiplier: 100,
    isTrend: true,
  })

  return (
    <div css={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gridGap: tokens.spacing[16] }}>
      <InsightCard label="Duration (mean)" value={formatDurationMs(insights.summaryTimings.mean.value * 1000)} />
      <InsightCard
        label="Duration (95th percentile)"
        value={formatDurationMs(insights.summaryTimings.p95.value * 1000)}
      />
      <InsightCard label="Duration trend (mean)" value={durationTrend.value} color={durationTrend.color} />
      <InsightCard label="Total" value={insights.summaryOutcomes.total.value} />
      <InsightCard label="Failure rate" value={failureRate.value} color={failureRate.color} />
      <InsightCard label="Failure trend" value={failureTrend.value} color={failureTrend.color} />
    </div>
  )
}

const InsightCard = ({
  label,
  value,
  color = "primary",
}: {
  label: string
  value: string | number | React.ReactElement
  color?: TextProps["color"]
}) => (
  <SectionCard
    css={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: tokens.spacing[16],
      gap: tokens.spacing[8],
    }}
  >
    <Text color="secondary" lineHeight="intermediate">
      {label}
    </Text>
    {value !== undefined && value !== null ? (
      <Text color={color} weight="semi-bold" lineHeight="intermediate" css={{ fontSize: 32 }}>
        {value}
      </Text>
    ) : (
      value
    )}
  </SectionCard>
)
