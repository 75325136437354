/*
 * Copyright (C) 2018-2024 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

import React from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Page } from "../components/page"
import { useApiQuery } from "../queries"
import { Note } from "../ui-kit"

export const ShortLinkHandler = () => {
  const navigate = useNavigate()

  const params = useParams<{ shortLink: string }>()
  const location = useLocation()
  // As both short urls, for live page and command result page,
  // are handled here, we set isCommand flag if url includes /go/command
  const isCommandResultRedirect = location.pathname.includes("/go/command")

  const linkQuery = useApiQuery((api) =>
    api.sessions.getSessionUrlByShortId(params.shortLink ?? "", { isCommand: isCommandResultRedirect })
  )

  React.useEffect(() => {
    if (linkQuery.data) {
      localStorage.setItem("prevPage", JSON.stringify({ title: "", name: "CLI" }))

      navigate(linkQuery.data)
    }
  }, [navigate, linkQuery.data])

  return (
    <>
      {/* only render content in case of error */}
      {linkQuery.error ? (
        <Page scope="project" name="short-link-handler" title={`Redirecting...`} styles={{ alignItems: "flex-start" }}>
          <Note variant="danger">
            Couldn't redirect to the commands result page. The link you followed may be invalid, or you may not have the
            necessary access rights.
          </Note>
        </Page>
      ) : null}
    </>
  )
}
