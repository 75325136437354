/*
 * Copyright (C) 2018-2024 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

import React from "react"
import { Route, Routes } from "react-router-dom"
import { tokens } from "../design-system"
import { NotFound } from "../pages/not-found"

const AccordionRow = React.lazy(() => import("../components/accordion-row.demo"))
const ActionsList = React.lazy(() => import("../components/actions-list.demo"))
const Breadcrumbs = React.lazy(() => import("../ui-kit/breadcrumbs/breadcrumbs.demo"))
const Button = React.lazy(() => import("../components/button.demo"))
const CodeSnippet = React.lazy(() => import("../ui-kit/codesnippet/codesnippet.demo"))
const CommandBar = React.lazy(() => import("../components/command-bar.demo"))
const Dropdown = React.lazy(() => import("../ui-kit/dropdown/dropdown.demo"))
const Filter = React.lazy(() => import("../components/filter.demo"))
const Graph = React.lazy(() => import("../components/action-graph/graph.demo"))
const Icons = React.lazy(() => import("../components/icons.demo"))
const Input = React.lazy(() => import("../components/input.demo"))
const Label = React.lazy(() => import("../components/label.demo"))
const Link = React.lazy(() => import("../components/link.demo"))
const Logs = React.lazy(() => import("../ui-kit/logs/logs.demo"))
const Navigation = React.lazy(() => import("../components/navigation/navigation.demo"))
const NavigationMainDropdown = React.lazy(() => import("../components/navigation/main-dropdown.demo"))
const NavigationProjectDropdown = React.lazy(() => import("../components/navigation/project-dropdown.demo"))
const Note = React.lazy(() => import("../ui-kit/note/note.demo"))
const Pill = React.lazy(() => import("../ui-kit/pill/pill.demo"))
const ProgressBar = React.lazy(() => import("../ui-kit/progress-bar/progress-bar.demo"))
const Switch = React.lazy(() => import("../ui-kit/switch/switch.demo"))
const Tabs = React.lazy(() => import("../ui-kit/tabs/tabs.demo"))
const Tag = React.lazy(() => import("../components/tag.demo"))
const Toast = React.lazy(() => import("../ui-kit/toast-notifications/toast.demo"))
const Tooltip = React.lazy(() => import("../ui-kit/tooltip/tooltip.demo"))

export const Demos = () => (
  <div css={{ padding: tokens.spacing[16] }}>
    <Routes>
      <Route path="/accordion" element={<AccordionRow />} />
      <Route path="/actions-list" element={<ActionsList />} />
      <Route path="/breadcrumbs" element={<Breadcrumbs />} />
      <Route path="/button" element={<Button />} />
      <Route path="/codesnippet" element={<CodeSnippet />} />
      <Route path="/command-bar" element={<CommandBar />} />
      <Route path="/dropdown" element={<Dropdown />} />
      <Route path="/filter" element={<Filter />} />
      <Route path="/graph" element={<Graph />} />
      <Route path="/icons" element={<Icons />} />
      <Route path="/input" element={<Input />} />
      <Route path="/label" element={<Label />} />
      <Route path="/link" element={<Link />} />
      <Route path="/logs" element={<Logs />} />
      <Route path="/navigation" element={<Navigation />} />
      <Route path="/navigation-main-dropdown" element={<NavigationMainDropdown />} />
      <Route path="/navigation-project-dropdown" element={<NavigationProjectDropdown />} />
      <Route path="/note" element={<Note />} />
      <Route path="/pill" element={<Pill />} />
      <Route path="/progress-bar" element={<ProgressBar />} />
      <Route path="/switch" element={<Switch />} />
      <Route path="/tabs" element={<Tabs />} />
      <Route path="/tag" element={<Tag />} />
      <Route path="/toast" element={<Toast />} />
      <Route path="/tooltip" element={<Tooltip />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </div>
)
