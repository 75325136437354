/*
 * Copyright (C) 2018-2024 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

import { format as formatDate } from "date-fns"
import React from "react"
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  type TooltipProps,
  XAxis,
  YAxis,
} from "recharts"
// eslint-disable-next-line import/no-unresolved
import { type NameType, type ValueType } from "recharts/types/component/DefaultTooltipContent"
import { P, match } from "ts-pattern"
import { AccordionRow } from "../../components/accordion-row"
import { EmptyState } from "../../components/empty-state"
import { ErrorState } from "../../components/error-state"
import { Cloud } from "../../components/icons"
import { LoadingIndicator } from "../../components/loading-indicator"
import { type OrganizationPageProps, Page, type PageOptions } from "../../components/page"
import { Text } from "../../components/text"
import { tokens } from "../../design-system"
import { type ApiResult, type Client, prefetchApiQuery, useApiQuery } from "../../queries"
import { cloudBuilderOrganizationTabs } from "./shared"

export const cloudBuilderActiveBuildersOptions = {
  icon: Cloud,
  text: "Cloud Builder",
  getPath: (id: string) => `/organizations/${id}/cloud-builder/builders`,
  prefetch: ({ organizationId }) => {
    if (organizationId) {
      prefetchApiQuery((api) => api.organizations.listBuilders(organizationId))
    }
  },
} satisfies PageOptions

export const CloudBuilderActiveBuilders = ({ organization }: OrganizationPageProps) => {
  const buildersQuery = useApiQuery((api) => api.organizations.listBuilders(organization.id))
  return (
    <Page
      tabs={cloudBuilderOrganizationTabs(organization.id)}
      name="cloud-builder-active-builders"
      title="Active builders"
      scope="project"
    >
      {match({ status: buildersQuery.status, builders: buildersQuery.data?.builders, error: buildersQuery.error })
        .with({ status: "loading" }, () => <LoadingIndicator />)
        .with({ status: "error", error: P.any }, ({ error }) => (
          <ErrorState
            title="Something went wrong"
            description={
              error?.apiErrorMessage ??
              "Error loading active builders. If the problem persists, please contact support."
            }
          />
        ))
        .with({ builders: P.when((builders) => Array.isArray(builders) && builders.length > 0) }, ({ builders }) => (
          <div>
            {builders.map((builder) => (
              <BuilderRow
                key={builder.id}
                organizationId={organization.id}
                builder={builder}
                isOpenDefault={builders.length === 1}
              />
            ))}
          </div>
        ))
        .otherwise(() => (
          <EmptyState
            Icon={Cloud}
            title="No active builders"
            description="There are no active builders in this project. Make sure Cloud Builder is configured in your project configuration and try triggering a new build."
          />
        ))}
    </Page>
  )
}

type Builder = ApiResult<Client, "organizations", "listBuilders">["builders"][0]

const BuilderRow = ({
  organizationId,
  builder,
  isOpenDefault,
}: {
  organizationId: string
  builder: Builder
  isOpenDefault?: boolean
}) => {
  const [isOpen, setIsOpen] = React.useState(isOpenDefault ?? false)

  const { startTime, endTime } = useTimestamps()
  const builderMetricsQuery = useApiQuery(
    (api) =>
      api.organizations.getBuilderMetrics(organizationId, builder.id, {
        start: startTime.toISOString(),
        end: endTime.toISOString(),
      }),
    {
      enabled: isOpen,
    }
  )
  const timeSeries = builderMetricsQuery.data
    ? generateTimeSeriesData(
        // @ts-expect-error The API doesn't return exact types for this API result yet. Once it does, remove this ts-expect-error.
        builderMetricsQuery.data?.timeSeries,
        startTime,
        endTime
      )
    : []
  const storageKeys = Object.keys(builderMetricsQuery.data?.timeSeries[0].values ?? {}).filter((key) =>
    key.startsWith("storage_")
  )

  return (
    <AccordionRow
      isOpenDefaultValue={isOpenDefault}
      onChangeOpen={() => setIsOpen((current) => !current)}
      styles={{ minHeight: "initial", userSelect: "none" }}
      main={
        <div css={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: tokens.spacing[12] }}>
          <Text>
            Builder <Text weight="semi-bold">{builder.id}</Text>
          </Text>
          <CircleSeparator />
          <Text>
            <Text weight="semi-bold">{builder.shape.virtualCpu}</Text> vCPU{" "}
            <Text weight="semi-bold">{builder.shape.memoryMegabytes / 1024}</Text> GB RAM
          </Text>
          <CircleSeparator />
          <Text>
            {builder.shape.os}/{builder.shape.machineArch}
          </Text>
        </div>
      }
      metaInfo={<Text>Created on {formatDate(new Date(builder.created), "yyyy-MM-dd")}</Text>}
      contents={match(builderMetricsQuery)
        .with({ status: "loading" }, () => <LoadingIndicator styles={{ padding: `${tokens.spacing[32]} 0` }} />)
        .with({ status: "error", error: P.any }, ({ error }) => (
          <ErrorState
            title="Something went wrong"
            description={error?.apiErrorMessage ?? "Could not load metrics for this builder, please try again later."}
            css={{ padding: `${tokens.spacing[24]} 0` }}
          />
        ))
        .with({ status: "success", data: P.not(P.nullish) }, () => (
          <BuilderCharts timeSeries={timeSeries} storageKeys={storageKeys} />
        ))
        .otherwise(() => (
          <EmptyState
            Icon={Cloud}
            title="No metrics available"
            description="Could not load metrics for this builder, please try again later."
            css={{ padding: `${tokens.spacing[24]} 0` }}
          />
        ))}
    />
  )
}

const toFixedValue = (value: number) => {
  if (value < 0.1) {
    return value.toFixed(2)
  } else if (value < 10) {
    return value.toFixed(1)
  } else {
    return value.toFixed(0)
  }
}

const BuilderCharts = ({ timeSeries, storageKeys }: { timeSeries: MetricPoint[]; storageKeys: string[] }) => (
  <div
    css={{
      display: "flex",
      flexDirection: "column",
      gap: tokens.spacing[56],
    }}
  >
    <div css={{ display: "flex", gap: tokens.spacing[24] }}>
      <div
        css={{
          "height": 200,
          "maxWidth": 800,
          "width": "100%",
          "display": "flex",
          "flexDirection": "column",
          "gap": tokens.spacing["16"],
          ">*": { flex: 1 },
        }}
      >
        <ComputeUsageChart
          title="CPU"
          data={timeSeries}
          metricNames={["cpu_avg", "cpu_max"]}
          formatter={(value) => toFixedValue(value) + "%"}
        />
        <ComputeUsageChart title="Memory" data={timeSeries} metricNames={["mem_used"]} formatter={formatBytes} />
      </div>
      <div
        css={{
          "width": "100%",
          "display": "flex",
          "flexDirection": "column",
          "gap": tokens.spacing["16"],
          ">*": { flex: 1 },
        }}
      >
        <ComputeUsageChart
          title="CPU I/O Wait"
          data={timeSeries}
          metricNames={["io_wait_avg", "io_wait_max"]}
          formatter={(value) => toFixedValue(value) + "%"}
        />
        <ComputeUsageChart
          title="Storage"
          data={timeSeries}
          metricNames={storageKeys}
          nameFormatter={(name) => name.replace("storage_used_percent_", "")}
          formatter={(value) => toFixedValue(value) + "%"}
        />
      </div>
    </div>
  </div>
)

const CircleSeparator = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="3" height="3" fill="none">
    <circle cx="1.5" cy="1.5" r="1.5" fill={tokens.colors["element-500"]} />
  </svg>
)

const getCurrentTimestamps = () => {
  const now = new Date()
  const thirtyMinutesAgo = new Date(now.getTime() - 30 * 60 * 1000)
  return {
    startTime: thirtyMinutesAgo,
    endTime: now,
  }
}

const useTimestamps = () => {
  const [timestamps, _setTimestamps] = React.useState(getCurrentTimestamps())

  // TODO For the time being we do not return new timestamps on an interval, as this will trigger a completely new query,
  // which in turn will cause us to show the loading state temporarily. The reason for this is that the timestamps are
  // parameters to the query and as such used as query keys.
  // If we want to refresh the data regularly we need to work around this issue to avoid flickering the UI. For now, it's
  // okay to require the user to refresh the page or similar to get new data.

  // React.useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setTimestamps(getCurrentTimestamps())
  //   }, 30 * 1000)
  //   return () => clearInterval(intervalId)
  // }, [])

  return timestamps
}

// TODO Import from API instead. Currently it's not exposed by the API.
// Available metrics: https://buf.build/namespace/cloud/docs/main:namespace.cloud.compute.v1beta#namespace.cloud.compute.v1beta.GetInstanceMetricsRequest.MetricResource
type BuilderMetricType =
  | `cpu_${number}`
  | `cpu_avg`
  | `cpu_max`
  | `io_wait_avg`
  | `io_wait_max`
  | `storage_used_percent_${string}`
  | `mem_available`
  | `mem_used`
  | string // Namespace might add additional metrics in the future that we don't know about yet.

interface MetricPoint {
  time: Date
  cpu_avg?: number
  cpu_max?: number
  io_wait_avg?: number
  io_wait_max?: number
  mem_available?: number
  mem_used?: number
}

type BuilderTimeSeriesData = {
  timestamps: number[]
  values: Record<BuilderMetricType, (number | string)[]>
}

function generateTimeSeriesData(
  timeSeriesData: BuilderTimeSeriesData | BuilderTimeSeriesData[],
  startTime: Date,
  endTime: Date
): MetricPoint[] {
  const data = Array.isArray(timeSeriesData) ? timeSeriesData[0] : timeSeriesData
  const values = data.values

  const timeSpan = endTime.getTime() - startTime.getTime()
  const cpuAvg = values.cpu_avg || []
  const cpuMax = values.cpu_max || []
  const ioWaitAvg = values.io_wait_avg || []
  const ioWaitMax = values.io_wait_max || []
  const memAvailable = values.mem_available || []
  const memUsed = values.mem_used || []

  const storageKeys = Object.keys(values).filter((key) => key.startsWith("storage_"))

  // Assumed to be the same for all, but even so...
  const maxLength = Math.max(
    cpuAvg.length,
    cpuMax.length,
    ioWaitAvg.length,
    ioWaitMax.length,
    memAvailable.length,
    memUsed.length
  )

  const interval = timeSpan / (maxLength - 1)

  const result: MetricPoint[] = []

  for (let i = 0; i < maxLength; i++) {
    const time = new Date(startTime.getTime() + interval * i)
    // TODO Assume everything is a number for now.
    const point: MetricPoint = {
      time,
      cpu_avg: cpuAvg[i]! as number,
      cpu_max: cpuMax[i]! as number,
      io_wait_avg: ioWaitAvg[i]! as number,
      io_wait_max: ioWaitMax[i]! as number,
      mem_available: memAvailable[i]! as number,
      mem_used: memUsed[i]! as number,
    }
    for (const key of storageKeys) {
      ;(point as MetricPoint & Record<string, number>)[key] = values[key][i]! as number
    }
    result.push(point)
  }

  return result
}

const labelForMetric = (metric: BuilderMetricType) =>
  ({
    cpu_avg: "CPU avg",
    cpu_max: "CPU max",
    io_wait_avg: "I/O wait avg",
    io_wait_max: "I/O wait max",
    mem_available: "Memory available",
    mem_used: "Memory used",
  }[metric] ?? metric)

function formatBytes(bytes: number): string {
  if (bytes === 0) return "0 Bytes"
  const k = 1024
  const sizes = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i]
}

export const formatPercent = (value: number) => `${(value * 100).toFixed(0)}%`
const formatTimeHours = (value: string) => new Date(value).getHours().toString().padStart(2, "0")
const formatTimeMinutes = (value: string) => new Date(value).getMinutes().toString().padStart(2, "0")
const formatTime = (value: string) => `${formatTimeHours(value)}:${formatTimeMinutes(value)}`

const colors = [
  tokens.colors["element-error"],
  tokens.colors["element-highlight"],
  tokens.colors["anchor-primary"],
  tokens.colors["element-progress"],
]

// TODO Axis & tooltip should use the data as received. Sometimes there's more than one line, sometimes just one. Etc.
export const ComputeUsageChart = ({
  title,
  metricNames,
  nameFormatter = labelForMetric,
  data,
  formatter,
  ticks = 3,
}: {
  title: string
  metricNames: BuilderMetricType[]
  formatter?: (value: number) => string
  nameFormatter?: (name: string) => string
  ticks?: number
  data: unknown[]
}) => (
  <div css={{ display: "flex", flexDirection: "column", gap: tokens.spacing[12] }}>
    <Text weight="bold">{title}</Text>
    <div css={{ height: 160 }}>
      <ResponsiveContainer
        width="100%"
        height="100%"
        css={{
          "fontFamily": tokens.typography["font-family-sans-serif"],
          "fontWeight": 500,
          // Axis labels
          ".recharts-text": {
            fill: tokens.colors["text-secondary"],
          },
        }}
      >
        <LineChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
          <CartesianGrid strokeDasharray="3 3" stroke={tokens.colors["element-400"]} />
          <XAxis dataKey="time" tickFormatter={formatTime} />
          <YAxis tickCount={ticks} orientation="right" tickFormatter={formatter} padding={{ top: 12 }} />
          <Legend formatter={nameFormatter} />
          {metricNames.map((metricName, index) => (
            <Line
              key={metricName}
              type="monotone"
              name={metricName}
              dataKey={metricName}
              stroke={colors[index % colors.length]}
              strokeWidth={2}
              activeDot={{ r: 5, stroke: tokens.colors["anchor-primary"], strokeWidth: 2 }}
              dot={false}
            />
          ))}
          <Tooltip
            content={<ComputeUsageTooltip nameFormatter={nameFormatter ?? labelForMetric} />}
            cursor={{ stroke: `${tokens.colors["text-tertiary"]}`, strokeWidth: 1, fill: "rgba(0,0,0,0)" }}
            formatter={formatter as any}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  </div>
)

const ComputeUsageTooltip = ({
  active,
  payload,
  formatter,
  nameFormatter,
}: TooltipProps<ValueType, NameType> & {
  formatter?: (value: number | string) => string
  nameFormatter: (value: string) => string
}) => {
  if (active && payload && payload.length) {
    return (
      <div
        css={{
          background: tokens.colors["element-000"],
          padding: `${tokens.spacing[8]} ${tokens.spacing[32]} ${tokens.spacing[8]} ${tokens.spacing[8]}`,
          border: `1px solid ${tokens.colors["border-secondary"]}`,
          borderRadius: "2px",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          gap: tokens.spacing[8],
        }}
      >
        {payload.map((item) => (
          <React.Fragment key={item.dataKey}>
            <Text>
              {nameFormatter(item.name as BuilderMetricType)}:{" "}
              {formatter ? formatter(item.value as BuilderMetricType) : item.value}
            </Text>
          </React.Fragment>
        ))}
      </div>
    )
  }
  return null
}
