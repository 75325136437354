/*
 * Copyright (C) 2018-2024 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

import {
  type ColumnDef,
  type Table as TableDataType,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { add, sub } from "date-fns"
import React from "react"
import { Area, AreaChart, ResponsiveContainer, Tooltip } from "recharts"
// eslint-disable-next-line import/no-unresolved
import { type ValueType } from "recharts/types/component/DefaultTooltipContent"
import { P, match } from "ts-pattern"
import {
  ActionRuntimeSummary,
  InsightsActionGroup,
  type InsightsSummaryResultEntry,
  type NscBuildUsageRecord,
} from "@garden-io/platform-api-types"
import { isApiError } from "../../api/utils"
import { EmptyState } from "../../components/empty-state"
import { ErrorState } from "../../components/error-state"
import { ArrowDown, ArrowUp, ChevronRight, Cloud, Icon } from "../../components/icons"
import { Link } from "../../components/link"
import { LoadingIndicator } from "../../components/loading-indicator"
import { type OrganizationPageProps, Page } from "../../components/page"
import { Table } from "../../components/table"
import { Text } from "../../components/text"
import { tokens } from "../../design-system"
import { type ApiResult, type Client, useApiQuery } from "../../queries"
import { InfoTooltip, Note } from "../../ui-kit"
import { useVirtualLinkClickHandler } from "../../utils/hooks/virtual-link-click-handler"
import { CustomizedTooltip } from "../usage/usage"
import { formatPercent } from "./cloud-builder-overview"
import { cloudBuilderOrganizationTabs, cloudBuilderOverviewOptions } from "./shared"

const getFallbackRate = (projectItem: ProjectItemResult) => {
  const rate =
    (projectItem.preferred?.summaryOutcomes.total.value ?? 0) /
    ((projectItem.actual?.summaryOutcomes.total.value || 1) + (projectItem.preferred?.summaryOutcomes.total.value ?? 0))
  return { rate, isHigh: rate > 0.1 }
}

const getColumns = (): ColumnDef<ProjectItemResult>[] => [
  {
    id: "projects",
    header: "Project",
    accessorFn: (row) => row.projectName,
    cell: ({ row }) => row.original.projectName,
  },
  {
    id: "buildMinutes",
    header: "Build minutes",
    accessorFn: (row) => row.actual?.summaryOutcomes.total.value || 0,
  },
  {
    id: "cloudBuilderMeanTiming",
    header: "Mean build time",
    accessorFn: (row) => row.actual?.summaryTimings.mean.value || 0,
    cell: ({ row }) =>
      `${parseFloat(row.original.actual?.summaryTimings.mean.value.toString() || "0").toFixed(2) || 0}s`,
  },
  {
    id: "failureRate",
    header: "Failure rate",
    accessorFn: (row) => row.actual?.summaryOutcomes.failureRate.value || 0,
    cell: ({ row }) => formatPercent(row.original.actual?.summaryOutcomes.failureRate.value || 0),
  },
  {
    id: "fallbackRate",
    header: "Fallback rate",
    accessorFn: (row) => {
      return (
        (row.preferred?.summaryOutcomes.total.value ?? 0) /
        ((row.actual?.summaryOutcomes.total.value || 1) + (row.preferred?.summaryOutcomes.total.value ?? 0))
      )
    },
    cell: ({ row }) => {
      const fallbackRate = getFallbackRate(row.original)
      return (
        <Text
          color={fallbackRate.isHigh ? "text-error" : "text-success"}
          weight={fallbackRate.isHigh ? "semi-bold" : "regular"}
        >
          {formatPercent(fallbackRate.rate)}
        </Text>
      )
    },
  },
  {
    id: "fallbackMeanTiming",
    header: "Fallback mean build time",
    accessorFn: (row) => row.preferred?.summaryTimings.mean.value || 0,
    cell: ({ row }) =>
      `${parseFloat(row.original.preferred?.summaryTimings.mean.value.toString() || "0").toFixed(2) || 0}s`,
  },
  {
    id: "controls",
    header: "",
    accessorFn: (row) => row,
    cell: () => {
      return <Icon Component={ChevronRight} title={null} />
    },
  },
]

type BuildUsageMetric = { count: number; unitMinutes: number; wallMinutes: number; date: Date }

type ProjectItemResult = {
  projectId: string
  projectName: string
  actual?: InsightsSummaryResultEntry
  preferred?: InsightsSummaryResultEntry
}

/**
 * Combine aggregate insights for both Cloud Builder builds and fallback builds in order to
 * display them later as a table or list.
 */
const useOrganizationProjectsMetrics = (organizationId: string) => {
  const insightsQueryActual = useApiQuery((api) =>
    api.insights.actionsAggregate(organizationId, {
      actualRuntimes: [ActionRuntimeSummary.RemoteGardenCloud],
      groupBy: [InsightsActionGroup.Project],
    })
  )
  const insightsQueryPreferred = useApiQuery((api) =>
    api.insights.actionsAggregate(organizationId, {
      preferredRuntimes: [ActionRuntimeSummary.RemoteGardenCloud],
      groupBy: [InsightsActionGroup.Project],
    })
  )

  return match({ insightsQueryActual, insightsQueryPreferred })
    .with({ insightsQueryActual: { status: "loading" } }, { insightsQueryPreferred: { status: "loading" } }, () => ({
      status: "loading",
      data: undefined,
    }))
    .with({ insightsQueryActual: { status: "error" } }, { insightsQueryPreferred: { status: "error" } }, () => ({
      status: "error",
      data: undefined,
      error: new Error("Error fetching Cloud Builder project metrics"),
    }))
    .with(
      {
        insightsQueryActual: { status: "success", data: P.select("actual") },
        insightsQueryPreferred: { status: "success", data: P.select("preferred") },
      },
      ({ preferred, actual }) => {
        const projects = new Map<
          string,
          {
            projectId: string
            projectName: string
            actual?: InsightsSummaryResultEntry
            preferred?: InsightsSummaryResultEntry
          }
        >()

        preferred.results.forEach((result) => {
          if (result.projectId) {
            projects.set(result.projectId, {
              projectId: result.projectId,
              projectName: result.projectName!, // Present when grouping by project.
              preferred: result,
            })
          }
        })
        actual.results.forEach((result) => {
          if (result.projectId) {
            const current = projects.get(result.projectId) || {}
            projects.set(result.projectId, {
              ...current,
              projectId: result.projectId,
              projectName: result.projectName!, // Present when grouping by project.
              actual: result,
            })
          }
        })
        return {
          status: "success",
          data: Array.from(projects.values()),
        }
      }
    )
    .otherwise(() => ({
      status: "error",
      error: new Error("Unknown error fetching Cloud Builder project metrics"),
      data: undefined,
    }))
}

export const CloudBuilderOrganization = ({ organization, user }: OrganizationPageProps) => {
  const org = user?.organizations.find((org) => org.id === organization.id)
  const isCloudBuilderEnabled = org?.cloudBuilderEnabled

  // This should not happen AFAIK -E
  if (!org) {
    return (
      <Page name="cloud-builder-organization" title="Cloud Builder" scope="organization">
        <Note variant="warning">
          Unable to load organization information. If the problem persists, please contact support.
        </Note>
        <EmptyState
          title="Cloud Builder page error"
          Icon={Cloud}
          description={
            <div css={{ display: "flex", flexDirection: "column", gap: tokens.spacing[16] }}>
              <Text>Unable to load cloud builder settings.</Text>
            </div>
          }
        />
      </Page>
    )
  }

  if (!isCloudBuilderEnabled) {
    return (
      <Page name="cloud-builder-organization" title="Cloud Builder" scope="organization">
        <EmptyState
          title="Cloud Builder is not enabled"
          Icon={Cloud}
          description={
            <div css={{ display: "flex", flexDirection: "column", gap: tokens.spacing[16] }}>
              <Text>
                The Garden Cloud Builder is not enabled for this organization.
                <br />
                You can enable it from the{" "}
                <Link to={`/organizations/${organization.id}`}>organization settings page</Link>.
              </Text>
              <Text>
                To learn more about the Garen Cloud Builder, check out the{" "}
                <Link to="https://docs.garden.io/v/enterprise/features/cloud-builder#configuration">
                  Cloud Builder documentation
                </Link>
              </Text>
            </div>
          }
        />
      </Page>
    )
  }

  return (
    <Page
      name="cloud-builder-organization"
      title="Cloud Builder"
      scope="organization"
      tabs={cloudBuilderOrganizationTabs(organization.id)}
    >
      <PageContent organization={organization} user={user} />
    </Page>
  )
}

const PageContent = ({ organization }: OrganizationPageProps) => {
  const usageQuery = useApiQuery((api) => api.organizations.getUsage(organization.id))
  const organizationProjectsMetrics = useOrganizationProjectsMetrics(organization.id)

  const table = useReactTable({
    data: organizationProjectsMetrics?.data || [],
    columns: getColumns(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const defaultErrorMessage =
    "There was an error loading your projects. If the problem persists, please contact support."

  const hasHighFallbackRate =
    organizationProjectsMetrics.data?.some((project) => getFallbackRate(project).isHigh) ?? false

  return (
    <>
      {match({ usageQuery, organizationProjectsMetrics })
        // Provide a loading indicator while any of the queries is still loading.
        .with({ usageQuery: { status: "loading" } }, { organizationProjectsMetrics: { status: "loading" } }, () => (
          <LoadingIndicator />
        ))
        // Display an error state if any of the queries failed.
        .with(
          P.union(
            { usageQuery: { status: "error", error: P.select("error") } },
            { organizationProjectsMetrics: { status: "error", error: P.select("error") } }
          ),
          ({ error }) => (
            <ErrorState
              title="Something went wrong"
              description={isApiError(error) ? error.apiErrorMessage ?? defaultErrorMessage : defaultErrorMessage}
            />
          )
        )
        .with(
          P.union(
            {
              usageQuery: {
                status: "success",
                data: { usage: { total: { buildCount: 0, buildUnitMinutes: 0, buildWallMinutes: 0 } } },
              },
            },
            {
              organizationProjectsMetrics: {
                status: "success",
                // When there are no insights for any projects, the array should be empty.
                data: P.when((value) => !value || (Array.isArray(value) && value.length === 0)),
              },
            }
          ),
          () => (
            <EmptyState
              title="No cloud builds have run"
              Icon={Cloud}
              description={
                <div css={{ display: "flex", flexDirection: "column", gap: tokens.spacing[16] }}>
                  <Text>
                    To get started with the Garden Cloud Builder you must configure your project to use it. For detailed
                    instructions, please refer to our{" "}
                    <Link to="https://docs.garden.io/v/enterprise/features/cloud-builder#configuration">
                      Cloud Builder documentation
                    </Link>
                  </Text>
                  <Text>You will see a list of all projects that are using Cloud Builder here.</Text>
                </div>
              }
            />
          )
        )
        // When the user has exceeded their limits, provide a warning message along with the usage charts and projects.
        .with(
          {
            usageQuery: {
              status: "success",
              data: P.when(({ limits, usage }) =>
                [
                  limits.buildCount > 0 && usage.total.buildCount >= limits.buildCount,
                  limits.buildWallMinutes > 0 && usage.total.buildWallMinutes >= limits.buildWallMinutes,
                  limits.buildUnitMinutes > 0 && usage.total.buildUnitMinutes >= limits.buildUnitMinutes,
                ].some(Boolean)
              ),
            },
          },
          () => {
            const result = usageQuery.data!
            const buildCount = result.limits.buildCount
            const buildMinutes = result.limits.buildUnitMinutes / result.limits.builderNumCpus
            return (
              <div css={{ display: "flex", flexDirection: "column", gap: tokens.spacing[56] }}>
                <Note variant="warning" multiline>
                  You've exceeded your Cloud Builder usage for this month. Your plan includes {buildCount} builds and{" "}
                  {buildMinutes} minutes. Please contact us in order to continue using Garden Cloud Builder.
                </Note>
                <ComputeCharts data={usageQuery.data} />
                <ProjectsTable table={table} hasHighFallbackRate={hasHighFallbackRate} />
              </div>
            )
          }
        )
        // The default case is to display the usage charts and projects table when possible.
        .with(
          P.intersection({ usageQuery: { status: "success" } }, { organizationProjectsMetrics: { status: "success" } }),
          () => (
            <div css={{ display: "flex", flexDirection: "column", gap: tokens.spacing[56] }}>
              <ComputeCharts data={usageQuery.data} />
              <ProjectsTable table={table} hasHighFallbackRate={hasHighFallbackRate} />
            </div>
          )
        )
        // This shouldn't happen based on the other cases, but if it does we display the empty state with a warning that something may have gone wrong.
        .otherwise(() => (
          <>
            <Note variant="warning">
              There was a problem displaying your Cloud Builder usage. If the problem persists, please contact support.
            </Note>
            <EmptyState
              title="Cloud Builder must be configured"
              Icon={Cloud}
              description={
                <div css={{ display: "flex", flexDirection: "column", gap: tokens.spacing[16] }}>
                  <Text>
                    To get started with the Garden Cloud Builder you must configure your project to use it. For detailed
                    instructions, please refer to our{" "}
                    <Link to="https://docs.garden.io/v/enterprise/features/cloud-builder#configuration">
                      Cloud Builder documentation
                    </Link>
                    .
                  </Text>
                  <Text>You will see a list of all projects that are using Cloud Builder here.</Text>
                </div>
              }
            />
          </>
        ))}
    </>
  )
}

const ComputeCharts = ({ data }: { data?: ApiResult<Client, "organizations", "getUsage"> }) => {
  if (!data) return null
  return (
    <div css={{ "display": "flex", "gap": tokens.spacing[56], ">div": { flex: 1 } }}>
      <ComputeChart
        data={data.usage.perDay}
        dataKey="buildUnitMinutes"
        title="Compute minutes"
        highlightedValue={`${data.usage.total.buildUnitMinutes / data.limits.builderNumCpus} unit minutes`}
        highlightedValueDescription={`Out of the ${
          data.limits.buildUnitMinutes / data.limits.builderNumCpus
        } allotted minutes per month`}
      />
      <VerticalSeparator />
      <ComputeChart
        data={data.usage.perDay}
        dataKey="buildCount"
        title="Number of builds"
        highlightedValue={`${data.usage.total.buildCount} builds`}
        highlightedValueDescription={`Out of the ${data.limits.buildCount} allotted builds per month`}
      />
    </div>
  )
}

const VerticalSeparator = () => (
  <hr css={{ width: 1, height: "100%", border: "none", margin: 0, backgroundColor: tokens.colors["element-300"] }} />
)

const CloudBuilderUsageChart = ({
  data,
  dataKey,
  formatTooltipValue,
}: {
  data: NscBuildUsageRecord[]
  dataKey: Omit<keyof BuildUsageMetric, "date">
  formatTooltipValue: (value: ValueType) => string
}) => {
  const backFillMissingValues = (d: NscBuildUsageRecord[], interval: number) => {
    const result: NscBuildUsageRecord[] = []
    const startsAt = sub(new Date(), { days: interval })
    for (let i = 1; i <= interval; i++) {
      const date = add(startsAt, { days: i })
      const point = data?.find((p) => {
        return new Date(p.date).getMonth() === date.getMonth() && new Date(p.date).getDate() === date.getDate()
      })
      if (point) {
        result.push(point)
      } else {
        result.push({
          date: date.toISOString(),
          buildCount: 0,
          buildUnitMinutes: 0,
          buildWallMinutes: 0,
        })
      }
    }
    return result
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        data={backFillMissingValues(data, 60).map((item) => ({
          date: item.date,
          value: item[dataKey as keyof NscBuildUsageRecord],
        }))}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <Tooltip
          content={<CustomizedTooltip />}
          cursor={{
            stroke: `${tokens.colors["text-tertiary"]}`,
            strokeWidth: 1,
            fill: "rgba(0,0,0,0)",
          }}
          formatter={(value) => formatTooltipValue(value)}
        />
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={tokens.colors["anchor-primary"]} stopOpacity={0.2} />
            <stop offset="100%" stopColor={tokens.colors["anchor-primary"]} stopOpacity={0} />
          </linearGradient>
        </defs>
        <Tooltip />
        <Area type="monotone" dataKey="value" stroke={tokens.colors["anchor-primary"]} fill="url(#colorUv)" />
      </AreaChart>
    </ResponsiveContainer>
  )
}

const ComputeChart = ({
  title,
  highlightedValue,
  highlightedValueDescription,
  data,
  dataKey,
}: {
  title: string
  highlightedValue: string
  highlightedValueDescription: string
  data: NscBuildUsageRecord[]
  dataKey: Omit<keyof BuildUsageMetric, "date">
}) => (
  <div
    css={{
      "display": "flex",
      "justifyContent": "space-between",
      "gap": tokens.spacing[16],
      "height": 80,
      ">div": {
        flex: 1,
      },
    }}
  >
    <div css={{ display: "flex", flexDirection: "column", gap: tokens.spacing[24] }}>
      <Text weight="bold">{title}</Text>
      <div css={{ display: "flex", flexDirection: "column", gap: tokens.spacing[8] }}>
        <Text size="large" weight="semi-bold" paragraph>
          {highlightedValue}
        </Text>
        <Text size="small" color="tertiary" paragraph>
          {highlightedValueDescription}
        </Text>
      </div>
    </div>
    <CloudBuilderUsageChart data={data} dataKey={dataKey} formatTooltipValue={(value) => `${value}`} />
  </div>
)

const ProjectsTable = ({
  table,
  hasHighFallbackRate,
}: {
  table: TableDataType<ProjectItemResult>
  hasHighFallbackRate: boolean
}) => {
  const virtualLinkClickHandler = useVirtualLinkClickHandler()
  return (
    <div css={{ overflowX: "auto", display: "flex", flexDirection: "column", gap: tokens.spacing[12] }}>
      {hasHighFallbackRate ? (
        <Note variant="warning" multiline>
          One or more projects has a high build fallback rate, which suggests that something might be misconfigured
          leading to slower build runtimes being selected. Please refer to our Cloud Builder documentation and make sure
          your project is configured correctly. If the issue persists, please get in touch with us.
        </Note>
      ) : null}
      <Table
        css={{
          "tr:hover": {
            backgroundColor: tokens.colors["element-100"],
            cursor: "pointer",
          },
          "tr:hover .link-indicator": {
            display: "inherit",
          },
        }}
      >
        <thead
          css={{
            backgroundColor: tokens.colors["element-100"],
          }}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const sorted = header.column.getIsSorted()
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    onClick={header.column.getToggleSortingHandler()}
                    css={{
                      ...(header.column.getCanSort() ? { cursor: "pointer", userSelect: "none" } : {}),
                    }}
                  >
                    <div css={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      <Text color={sorted ? "primary" : "secondary"}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </Text>
                      {header.id === "fallbackRate" ? (
                        <InfoTooltip
                          id="fallbackRate"
                          infoText="The rate of builds that we could not build in Cloud Builder and had to fall back to local builds or similar."
                        />
                      ) : null}
                      <Icon
                        Component={sorted === "desc" ? ArrowUp : ArrowDown}
                        title={`Sort ${sorted === "asc" ? "ascending" : "descending"}`}
                        size="small"
                        css={{
                          visibility: sorted ? "visible" : "hidden",
                        }}
                      />
                    </div>
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody
          css={{
            "a": {
              color: tokens.colors["text-primary"],
              textDecoration: "none",
            },
            "a:hover": {
              textDecoration: "underline",
            },
          }}
        >
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  onClick={virtualLinkClickHandler(cloudBuilderOverviewOptions.getPath(row.original.projectId))}
                  css={{
                    height: 40,
                    maxWidth: "20rem",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontFamily:
                      tokens.typography[
                        cell.column.id === "builds" ? "font-family-monospace" : "font-family-sans-serif"
                      ],
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
