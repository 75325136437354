/*
 * Copyright (C) 2018-2024 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

import { css } from "@emotion/css"
import React from "react"
import { ErrorState } from "../error-state"
import { Page } from "../page"
import { Text } from "../text"

export const EphemeralClustersAuthError = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const queryParams = Object.fromEntries(searchParams)

  return (
    <Page scope="none" name="ephemeral-clusters-auth-error">
      <ErrorState
        title="Authentication error"
        description={
          <div>
            The authenticated account <Text weight="bold">{queryParams.username ? queryParams.username : ""}</Text>{" "}
            doesn't have access to this cluster, check that you are logged in with the right GitHub account. You can
            only access your own clusters.
          </div>
        }
        className={css({ height: "100%" })}
      />
    </Page>
  )
}
